import React from 'react'

const Accomodation = () => {
    return (
        <div>
            Accomodation
        </div>
    )
}

export default Accomodation
