import { Typography } from '@mui/material'
import React from 'react'

const TermConditions = () => {
    return (
        <div className='main py-5 text-white'>
            <Typography variant='h6' className='text-center fs-1 mb-3 fontFamily fw-bold py-5' >Terms & Conditions</Typography>

            <ol>
                <li style={{ fontSize: "20px" }} className=' mb-3'>This website is owned and operated by Sirāt (Sirat2Sustainability Pvt Ltd). Throughout the website, the terms<strong> ‘we,’ ‘us’ </strong>and <strong>‘our’</strong> refer to <strong>Sirāt.</strong></li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>By using, accessing, or visiting the Sirāt website, you accept and agree to comply with the following terms of use (“Terms of Use”), whether as a guest or a registered user.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>For information on how we collect and use personal information, on cookies and other similar devices we use, and how to set your cookie preferences, please see our Privacy Policy. ‍ Changes to these terms</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>We may revise these Terms of Use at any time by amending this page. Please check this page from time to time to take notice of any changes we make, as they are binding.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>The content of this website is for your general information and use only. Sirāt reserves the right to modify the content of this website at any time, but we have no obligation to update any information on the website, meaning the content may be out of date at any time.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these Terms of Use and other applicable terms and conditions, and that they comply with them.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>All intellectual property on, and contained in, this website and content and materials published on it, including but not limited to, text, design, graphics, logos, icons, images, videos, downloads, and software are the exclusive property of and owned by Sirāt, and are protected by copyright, trademark or other proprietary rights.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>You cannot modify, publish, transmit, transfer, sell, reproduce, create derivative works from this website, distribute, display, or in any way exploit any of the content, in whole or in part.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us.‍</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>You cannot establish a link to our site in any website that is not owned by you or others. Our site must not be framed on any other site, nor may you create a link to any part of our site.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>We reserve the right to withdraw linking permission without notice.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>If you wish to make any use of content on our site, please contact us using the contact form on this website.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>This website may contain links to external sites and social media apps on the internet, which are owned and operated by third parties. Such links should not be interpreted as approval by us of those linked websites or any information you may obtain from them. These links are provided for your convenience and information only. Sirāt has no control over and is not responsible for the availability of, or the information and content of, any third party’s website. You should contact the site administrator or webmaster for those external sites, if you have any concerns regarding such links or the content located on such external sites. Sirāt assumes no responsibility for the content of website linked from our site and we will not be liable for any loss of damage that may arise from your use of them.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>In using our website, you agree that you shall not: do anything to encourage, procure or carry out any criminal activity; use our website for any purpose other than your personal use; use the website for any purpose that is unlawful or prohibited by these Terms of Use; email, transmit or otherwise disseminate any content which is in our sole opinion defamatory, obscene, in breach of copyright or any other third party rights, vulgar or indecent or may have the effect of being harassing, threatening, abusive or hateful or that otherwise degrades or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability or is otherwise unlawful; advertise or promote third party or your own products or services including by way of the distribution of 'spam' email; transfer files that contain viruses, trojans or other harmful programs; and/or access or attempt to access the accounts of other users or penetrate or attempt to penetrate our site's security measures.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures in future, you must treat such information as confidential. You must not disclose it to any third party.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms of Use.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>Sirāt reserves the right, in its sole discretion, at any time to restrict, suspend or terminate your access to all or any part of the website for any reason without prior notice. Sirāt may change, suspend or discontinue all or any aspect of the website at any time, including the availability of any content, without prior notice or liability.</li>
                <li style={{ fontSize: "20px" }} className=' mb-3'>Your use of this website and any dispute arising out of such use are subject to the laws of the India. Any disputes arising in connection with these Terms of Use shall be settled before the courts of the India.</li>
            </ol>


        </div>
    )
}

export default TermConditions