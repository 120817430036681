import Home from './components/landingPage/home/index'

function UserLayout() {
    return (
        <div>
            <Home />
        </div>
    );
}

export default UserLayout;
