import React from 'react'
import Header from '../../header/header'
import Footer from '../../footer/footer'
import Organisations from './organisations';

const Index = () => {

    return (
        <div>
            <Organisations />
        </div>
    )
}

export default Index
